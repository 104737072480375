import React from "react"
import Img from "gatsby-image"
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBAnimation,
} from "mdbreact"
import ContactForm from "./contactForm"

const ContactSection = ({ map }) => {
  return (
    <section className="contact py-5">
      <MDBContainer fluid>
        <MDBRow>
          <MDBContainer className="my-md-5">
            <MDBRow className="mx-auto">
              <MDBCol size="12" md="8" className="text-center mx-auto">
                <MDBAnimation type="fadeInUp" reveal>
                  <h2 className="font-weight-bold">Get In Touch</h2>
                  <hr className="heading-hr" />
                </MDBAnimation>
              </MDBCol>
              <MDBCol lg="7" className="text-center mx-md-auto mb-5">
                <MDBAnimation type="fadeInUp" delay="0.3s" reveal>
                  <p className="lead">
                    Looking for someone to help take your web presence to the
                    next level? Let's chat!
                  </p>
                </MDBAnimation>
              </MDBCol>
              <MDBCol size="12" md="12" className="mx-auto">
                <MDBAnimation type="fadeIn" delay="0.3s" reveal>
                  <MDBCard>
                    <MDBCardBody>
                      <MDBRow>
                        <MDBCol size="12" md="6">
                          <ContactForm />
                        </MDBCol>
                        <MDBCol size="12" md="6" className="d-none d-lg-block">
                          <Img
                            fluid={map}
                            alt="Map of Charlotte"
                            className="d-block w-100"
                          />
                        </MDBCol>
                      </MDBRow>
                    </MDBCardBody>
                  </MDBCard>
                </MDBAnimation>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </MDBRow>
      </MDBContainer>
    </section>
  )
}

export default ContactSection
