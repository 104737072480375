import React from "react"
import { Link } from "gatsby"
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBIcon,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBAnimation,
  MDBCardHeader,
} from "mdbreact"

const Capabilities = () => {
  return (
    <section className="about">
      <MDBContainer fluid className="py-md-5 py-3 about-section">
        <MDBRow className="py-2  my-3 my-md-5 justify-content-center">
          <MDBContainer>
            <MDBRow className="py-4 py-md-5 mx-auto justify-content-center">
              <MDBCol size="12">
                <MDBAnimation type="fadeInUp" reveal>
                  <h2 className="text-center font-weight-bold">
                    Capabilities
                    <hr className="heading-hr" />
                  </h2>
                </MDBAnimation>
              </MDBCol>

              <MDBCol lg="7" className="text-center mx-auto">
                <MDBAnimation type="fadeInUp" delay="0.3s" reveal>
                  <p className="lead">
                    As a web developer and graphic designer, I take projects
                    from{" "}
                    <Link to="/about" alt="Read more about me" title="About">
                      ideas to completed works.
                    </Link>
                  </p>
                </MDBAnimation>
              </MDBCol>
            </MDBRow>
            <MDBRow className="mb-0  text-center mx-auto justify-content-center">
              <MDBCol size="12" md="4" className="mb-3 mb-md-0">
                <MDBAnimation
                  type="fadeIn"
                  delay="0.2s"
                  reveal
                  className="animated-cards"
                >
                  <MDBCard>
                    <MDBCardHeader>
                      <MDBIcon
                        icon="palette"
                        className="mr-2 text-primary py-3"
                        size="3x"
                      />
                    </MDBCardHeader>
                    <MDBCardBody>
                      <MDBCardTitle className="font-weight-bold">
                        Designer
                      </MDBCardTitle>

                      <hr />

                      <MDBCardText>
                        I create graphics for all mediums. I have experience
                        designing logos, print media, graphics and apparel
                        design.
                      </MDBCardText>
                    </MDBCardBody>
                  </MDBCard>
                </MDBAnimation>
              </MDBCol>
              <MDBCol size="12" md="4" className="mb-3 mb-md-0">
                <MDBAnimation
                  type="fadeIn"
                  delay="0.3s"
                  reveal
                  className="animated-cards"
                >
                  <MDBCard>
                    <MDBCardHeader>
                      <MDBIcon
                        icon="code"
                        className="mr-2 text-primary py-3"
                        size="3x"
                      />
                    </MDBCardHeader>
                    <MDBCardBody>
                      <MDBCardTitle className="font-weight-bold">
                        Developer
                      </MDBCardTitle>
                      <hr />
                      <MDBCardText>
                        I have developed for a wide variety of CMS platforms. I
                        am currently focusing on Javascript based development
                        utilizing React and API content management systems.
                      </MDBCardText>
                    </MDBCardBody>
                  </MDBCard>
                </MDBAnimation>
              </MDBCol>
              <MDBCol size="12" md="4" className="mb-3 mb-md-0">
                <MDBAnimation
                  type="fadeIn"
                  delay="0.4s"
                  reveal
                  className="animated-cards"
                >
                  <MDBCard>
                    <MDBCardHeader>
                      <MDBIcon
                        icon="desktop"
                        className="mr-2 text-primary py-3"
                        size="3x"
                      />
                    </MDBCardHeader>
                    <MDBCardBody>
                      <MDBCardTitle className="font-weight-bold">
                        Tools
                      </MDBCardTitle>
                      <hr />

                      <MDBCardText>
                        I have experience building for Wordpress, Gatsby, React,
                        Marketo, and various other platforms.
                      </MDBCardText>
                    </MDBCardBody>
                  </MDBCard>
                </MDBAnimation>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </MDBRow>
      </MDBContainer>
    </section>
  )
}

export default Capabilities
