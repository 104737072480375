import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/hero"
import ProjectsSection from "../components/sections/projectsSection"
import Capabilities from "../components/sections/capabilities"
import ContactSection from "../components/sections/contactSection"

const IndexPage = ({ data }) => (
  <Layout>
    <SEO title="Web Developer & Graphic Designer" />
    <Hero background={data.hero.childImageSharp.fluid} />
    <ProjectsSection />
    <Capabilities />
    <ContactSection map={data.file.childImageSharp.fluid} />
  </Layout>
)

export default IndexPage

export const query = graphql`
  query ContactSectionQuery {
    file(relativePath: { eq: "clt-map.jpg" }) {
      id
      childImageSharp {
        fluid(maxWidth: 505, maxHeight: 473, cropFocus: CENTER, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    hero: file(relativePath: { eq: "hero_img.jpg" }) {
      id
      childImageSharp {
        fluid(
          maxWidth: 1920
          maxHeight: 1080
          quality: 90
          duotone: { highlight: "#007bff", shadow: "#202932", opacity: 90 }
        ) {
          src
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
