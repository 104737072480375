import React from "react"
import { Link } from "gatsby"
import BackgroundImage from "gatsby-background-image"
//MDB Hero Components
import {
  MDBJumbotron,
  MDBRow,
  MDBCol,
  MDBAnimation,
  MDBBtn,
  MDBIcon,
  MDBContainer,
} from "mdbreact"

function Hero({ background }) {
  return (
    <BackgroundImage fluid={background}>
      <MDBJumbotron fluid id="hero">
        <MDBRow className="pt-2 pt-md-5 my-2 my-md-5 text-center">
          <MDBContainer>
            <MDBCol size="12" className="pt-5 mt-5 mx-auto">
              <MDBAnimation type="fadeIn" delay="0.2s" reveal>
                <h1 className="white-text pb-3">
                  I'm <span className="font-weight-bold">Z</span>.{" "}
                  <br className="d-block d-md-none" />
                  <span className="font-weight-bold">Web Developer</span>{" "}
                  <br className="d-block d-md-none" />&{" "}
                  <span className="font-weight-bold">Designer.</span>
                </h1>
              </MDBAnimation>

              <MDBRow className="py-2 py-md-4">
                <MDBCol>
                  <MDBAnimation type="fadeIn" delay="0.5s" reveal>
                    <Link
                      to="/projects"
                      alt="View Projects"
                      title="View Projects"
                    >
                      <MDBBtn color="primary" tag="span">
                        <MDBIcon icon="folder-open" className="mr-2" />
                        View Projects
                      </MDBBtn>
                    </Link>
                  </MDBAnimation>
                </MDBCol>
              </MDBRow>
            </MDBCol>
          </MDBContainer>
        </MDBRow>
      </MDBJumbotron>
    </BackgroundImage>
  )
}
export default Hero

// function HeroText() {
//   const text = [
//     "I'm Z.",
//     "Web Designer",
//     "Web Developer",
//     "Graphic Designer",
//     "I Build for the modern web",
//   ]
//   render(
//     return(text.forEach(text => console.log(text))))
// }

// SVG Text function for the hero
// const HeroText = () => {
//   const cls1 = {
//     fontSize: "62.52px",
//     fill: "#444",
//   }
//   const cls2 = {
//     fill: "#444",
//     fontWeight: 500,
//     letterSpaceing: "0em",
//   }
//   const cls3 = {
//     fill: "#444",
//     fontWeight: 500,
//     letterSpaceing: "-0.02em",
//   }
//   const cls4 = {
//     fill: "#444",
//     fontWeight: 500,
//     letterSpaceing: "0em",
//   }
//   const cls5 = {
//     fill: "#444",
//     letterSpaceing: "-0.02em",
//   }
//   const cls6 = {
//     fill: "#444",
//     letterSpaceing: "0em",
//   }
//   return (
//     <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 869.12 70.43">
//       <g id="Layer_2" data-name="Layer 2">
//         <g id="Layer_1-2" data-name="Layer 1">
//           <text style={cls1} transform="translate(0 53.48)">
//             I Build
//             <tspan style={cls2} x="191.29" y="0">
//               Lightning
//             </tspan>
//             <tspan style={cls3} x="471.89" y="0">
//               F
//             </tspan>
//             <tspan style={cls4} x="506.63" y="0">
//               ast
//             </tspan>
//             <tspan x="594.89" y="0">
//               {" "}
//             </tspan>
//             <tspan style={cls5} x="610.43" y="0">
//               W
//             </tspan>
//             <tspan style={cls6} x="664.71" y="0">
//               ebsites
//             </tspan>
//           </text>
//         </g>
//       </g>
//     </svg>
//   )
// }
