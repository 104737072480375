import React from "react"
import { Link, StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardTitle,
  MDBView,
  MDBIcon,
  MDBCardBody,
  MDBBtn,
  MDBCardText,
  MDBAnimation,
} from "mdbreact"

const ProjectsSection = ({ data }) => {
  return (
    <>
      <section className="projects">
        <MDBContainer className="my-md-5 py-md-5 my-5 py-3">
          <MDBRow className="py-2  my-3 my-md-5 justify-content-center">
            <MDBCol size="12" align="center" className="col-md-12">
              <MDBAnimation type="fadeInUp" reveal>
                <h2 className="text-md-center font-weight-bold">
                  Featured Projects
                  <hr className="heading-hr" />
                </h2>
              </MDBAnimation>
            </MDBCol>
            <MDBCol size="11" lg="7" className="text-center mx-auto">
              <MDBAnimation type="fadeInUp" delay="0.3s" reveal>
                <p className="lead">
                  I'm always working on new projects. Here are{" "}
                  <Link to="/projects" title="Projects">
                    a few featured projects.{" "}
                  </Link>
                </p>
              </MDBAnimation>
            </MDBCol>
          </MDBRow>
          <MDBRow className="mx-auto justify-content-center">
            <FeaturedProjects />
          </MDBRow>
        </MDBContainer>
      </section>
    </>
  )
}

export default ProjectsSection

export const FeaturedProjects = () => (
  <StaticQuery
    query={graphql`
      {
        allPrismicProject(filter: { tags: { eq: "featured" } }, limit: 3) {
          edges {
            node {
              id
              uid
              data {
                category
                title {
                  text
                }
                subtitle {
                  text
                }
                hero_img {
                  alt
                  localFile {
                    id
                    childImageSharp {
                      fluid(
                        maxHeight: 300
                        maxWidth: 450
                        cropFocus: CENTER
                        quality: 90
                      ) {
                        ...GatsbyImageSharpFluid_withWebp
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={data => (
      <>
        {data.allPrismicProject.edges.map((document, index) => (
          <MDBCol
            size="12"
            md="6"
            lg="4"
            className="mb-4"
            key={document.node.id}
          >
            <MDBAnimation type="fadeIn" reveal delay={"0.3" * index + "s"}>
              <MDBCard className="card-zoom">
                <Link
                  to={`/projects/${document.node.uid}`}
                  alt="View Project"
                  title={document.node.data.title.text}
                >
                  <MDBView
                    waves
                    className="projects"
                    alt={document.node.data.hero_img.alt}
                  >
                    <Img
                      fluid={
                        document.node.data.hero_img.localFile.childImageSharp
                          .fluid
                      }
                      alt={document.node.data.hero_img.alt}
                    />
                  </MDBView>
                </Link>

                <MDBCardBody>
                  <Link
                    to={`/projects/${document.node.uid}`}
                    name="View Project"
                    alt="View Project"
                    title="View Project"
                  >
                    <div className="card-btn">
                      <MDBBtn color="primary" tag="span">
                        <MDBIcon icon="folder-open" />
                      </MDBBtn>
                    </div>
                  </Link>
                  <MDBCardTitle className="font-weight-bold" tag="h5">
                    {document.node.data.title.text}
                  </MDBCardTitle>

                  <MDBCardText>{document.node.data.subtitle.text}</MDBCardText>
                </MDBCardBody>
              </MDBCard>
            </MDBAnimation>
          </MDBCol>
        ))}
      </>
    )}
  />
)
